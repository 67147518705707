import React from 'react'

import Layout from '../components/layout'

const BlogIndexPage = () => (
  <Layout>
  </Layout>
)

export default BlogIndexPage
